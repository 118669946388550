import React from 'react'

const Td = ({ 
  children, 
  colSpan, 
  rowSpan, 
  className, 
  width, 
  noPadding, 
  style,
  fontSize,
  textRight,
  ...rest
}) => (
  <td
    {...rest}
    width={width}
    colSpan={colSpan}
    rowSpan={rowSpan}
    className={`${noPadding ? 'p-0' : 'p-1'} ${textRight ? 'text-right' : 'text-left'} ${className}`}
    style={{
      ...style,
      fontSize: fontSize ? fontSize : 13
    }}
  >
    {children}
  </td>
)

export default Td