import React from 'react'
import { Formik } from 'formik'
import { DateConvert } from 'utilities'
import { FilterModal, DatePicker, SelectSearch } from 'components'

const ModalFilter = ({ show, setShow, dropdownUrutan, data, setData }) => {
  const filter = data

  const formInitialValues = {
    active: filter?.active ?? false,
    tanggal_awal: filter?.tanggal_awal ?? "",
    tanggal_akhir: filter?.tanggal_akhir ?? "",
    order_option: filter.order_option ?? ""
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ ...values, active: true })
    } else {
      setData({ ...data, ...values, active: false })
    }
    setShow(false)
  }
  const onResetButtonClick = () => {
    setData(prev => ({
      ...prev,
      active: false,
      tanggal_awal: undefined,
      tanggal_akhir: undefined,
      order_option: undefined
    }))
  
    setShow(false)
  }
  const onChangeTanggal = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null
  
    setValues({
      ...values,
      tanggal_awal: startDate,
      tanggal_akhir: endDate,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, errors, touched, handleSubmit, setValues}) => { 
        console.log(values)
        return(
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick()}
          onFilterButtonClick={handleSubmit}>
          
          <DatePicker
            selectsRange
            label="Tanggal"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal"
            startDate={values.tanggal_awal ? new Date(values.tanggal_awal) : ''}
            endDate={values.tanggal_akhir ? new Date(values.tanggal_akhir) : ''}
            onChange={(dates) => onChangeTanggal(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            label="Urutan"
            placeholder="Pilih urutan"
            defaultValue={values.order_option ? dropdownUrutan.find(val => val.value === values.order_option) : null}
            option={dropdownUrutan ?? []}
            onChange={(val) => setValues({ ...values, order_option: val.value })}
            error={errors.order_option && touched.order_option && true}
            errorText={errors.order_option && touched.order_option && errors.order_option}
          />
        </FilterModal>
      )}}
    </Formik>
  )
}
export default ModalFilter;
