import Services from 'services'

class AnalisaBarangJadiRABApi {
    getAnalisaSingle(params) {
        return Services.get('/sales_order_spk/analisa_barang_jadi/', { params })
    }

    getDropdownTipeFinishing() {
        return Services.get("/sales_order_spk/d_finishing")
    }

    getKelompokPrelim(params) {
        return Services.get('/sales_order_spk/list_kelompok_prelim', { params })
    }

    // getItemPrelim(params) {
    //     return Services.get('/analisa_barang_jadi_rab/dropdown_item_prelim', { params })
    // }

    // getDropdownTipeProduksi(params) {
    //     return Services.get("/analisa_barang_jadi_rab/d_tipe_produksi", { params })
    // }

    // getDropdownBentuk(params) {
    //     return Services.get("/analisa_barang_jadi_rab/d_bentuk", { params })
    // }

    // getDropdownItemLabourCost(params) {
    //     return Services.get("/dropdown/upah", { params })
    // }

}

export default new AnalisaBarangJadiRABApi()