import React from "react"
import CurrencyInput from 'react-currency-input'

const NumberFormat = ({ 
  label,
  size,
  onChange,
  error,
  errorText,
  wrapperClassName,
  wrapperStyle,
  className,
  noMargin,
  ...rest
}) => {
  return (
    <div className={`${noMargin ? 'm-0' : 'mb-2'} ${wrapperClassName}`} style={wrapperStyle}>
      <small>{label}</small>
      <CurrencyInput
        {...rest}
        onChangeEvent={onChange}
        allowEmpty={true}
        thousandSeparator='.'
        decimalSeparator=','
        className={`form-control form-control-${size ? size : 'sm'} ${error && "is-invalid"} ${className}`}
      />
      <div className="invalid-feedback">{errorText}</div>
    </div>
  )
}

export default NumberFormat