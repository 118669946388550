import React, { useState, useEffect } from 'react'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { useFormik } from 'formik'
import axios from 'axios'
import { PenjualanLangsungApi } from 'api'

const ModalFilter = ({ SESSION_KEY, modalConfig, setModalConfig, data, setData }) => {
  const filter = data?.filter
  const formInitialValues = {
    tgl_penjualan_langsung_mulai: filter?.tgl_penjualan_langsung_mulai,
    tgl_penjualan_langsung_selesai: filter?.tgl_penjualan_langsung_selesai,
    customer: filter?.customer,
    petugas: filter?.petugas,
    id_barang: filter?.id_barang,
    metode_pembayaran: filter?.metode_pembayaran,
  }

  const [loading, setLoading] = useState(true)

  const [dropdown, setDropdown] = useState({
    customer: [{ value: undefined, label: 'Semua' }],
    petugas: [{ value: undefined, label: 'Semua' }],
    item_barang: [{ value: undefined, label: 'Semua' },],
    metode_pembayaran: [
      { value: undefined, label: 'Semua' },
      { value: "tunai", label: "Tunai" },
      { value: "kredit", label: "Kredit" },
    ],
  })

  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 }
      })
    } else {
      setData({ ...data, filter: { ...values, active: false } })
    }
    setModalConfig(false)
  }

  const formik = useFormik({ initialValues: formInitialValues, onSubmit: formSubmitHandler })

  const { values, setFieldValue, setValues, handleSubmit } = formik

  const onChangeTglPenjualan = (dates) => {
    const [start, end] = dates

    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({ ...values, tgl_penjualan_langsung_mulai: startDate, tgl_penjualan_langsung_selesai: endDate })
  }

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER)
    sessionStorage.removeItem(SESSION_KEY.PAGINATION)

    setData(prev => ({
      ...prev,
      filter: {
        tgl_penjualan_langsung_mulai: undefined,
        tgl_penjualan_langsung_selesai: undefined,
        customer: undefined,
        petugas: undefined,
        id_barang: undefined,
        metode_pembayaran: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      }
    }))

    setModalConfig(false)
  }

  const getInitialData = () => {
    axios.all([
      PenjualanLangsungApi.getCustomer(),
      PenjualanLangsungApi.getKaryawan(),
      PenjualanLangsungApi.getItemBarang(),
    ])
      .then(axios.spread((customer, petugas, itemBarang) => {
        const mapCustomer = customer.data.data.map(val => ({ value: val.id_customer, label: val.nama_customer }))
        const mapPetugas = petugas.data.data.map(val => ({ value: val.id_karyawan, label: val.nama_karyawan }))
        const mapItemBarang = itemBarang.data.data.map(val => ({ value: val.id_barang, label: val.nama_barang }))

        setDropdown(prev => ({
          ...prev,
          customer: prev.customer.concat(mapCustomer),
          petugas: prev.petugas.concat(mapPetugas),
          item_barang: prev.item_barang.concat(mapItemBarang),
        }))
      }))
      .catch(err => { })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getInitialData()

    return () => setDropdown({})
  }, [])

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        dateFormat='dd/MM/yyyy'
        label="Tgl. Penjualan Langsung"
        placeholderText="Pilih tanggal penjualan langsung"
        startDate={values?.tgl_penjualan_langsung_mulai ? new Date(values?.tgl_penjualan_langsung_mulai) : ''}
        endDate={values?.tgl_penjualan_langsung_selesai ? new Date(values?.tgl_penjualan_langsung_selesai) : ''}
        onChange={(dates) => onChangeTglPenjualan(dates)}
        monthsShown={2}
      />

      <SelectSearch
        key={values?.customer}
        label="Customer"
        loading={loading}
        placeholder="Pilih customer"
        defaultValue={dropdown?.customer?.find(item => item.value === values.customer)}
        option={dropdown.customer}
        onChange={val => setFieldValue('customer', val.value)}
      />

      <SelectSearch
        key={values?.petugas}
        label="Petugas Sales"
        loading={loading}
        placeholder="Pilih petugas sales"
        defaultValue={dropdown?.petugas?.find(item => item.value === values.petugas)}
        option={dropdown.petugas}
        onChange={val => setFieldValue('petugas', val.value)}
      />

      <SelectSearch
        key={values.id_barang}
        label="Item Barang"
        loading={loading}
        placeholder="Pilih Item barang"
        defaultValue={dropdown?.item_barang?.find(item => item.value === values.id_barang)}
        option={dropdown.item_barang}
        onChange={val => setFieldValue('id_barang', val.value)}
      />

      <SelectSearch
        key={values.metode_pembayaran}
        label="Metode Pembayaran"
        placeholder="Pilih metode pembayaran"
        defaultValue={dropdown?.metode_pembayaran?.find(item => item.value === values.metode_pembayaran)}
        option={dropdown.metode_pembayaran}
        onChange={val => setFieldValue('metode_pembayaran', val.value)}
      />
    </FilterModal>
  )
}

export default ModalFilter