import Services from "services"

class PenjualanLangsungApi {
  get(params) {
    return Services.get("/penjualan_langsung/page", { params })
  }

  getSingle(params) {
    return Services.get("/penjualan_langsung/single", { params })
  }

  getNomor(params) {
    return Services.get("/penjualan_langsung/no_baru", { params })
  }

  getDataBarang(params) {
    return Services.get("/penjualan_langsung/item_barang", { params })
  }

  save(data) {
    return Services.post("/penjualan_langsung", data)
  }

  saveCustomer(data) {
    return Services.post("/customer", data)
  }

  getKodeCustomer() {
    return Services.get("/customer/kode_customer")
  }

  export(params) {
    return Services.get("/penjualan_langsung/export", { params })
  }

  // Dropdown
  getCustomer() {
    return Services.get("/dropdown/customer")
  }

  getKaryawan() {
    return Services.get("/dropdown/karyawan")
  }

  getKelompokBarang() {
    return Services.get("/dropdown/kelompok")
  }

  getJenisBarang(params) {
    return Services.get("/dropdown/jenis", { params })
  }

  getItemBarang(params) {
    return Services.get("/dropdown/item_barang", { params })
  }

  getJenisCustomer() {
    return Services.get("/customer/jenis")
  }

  getProvinsi() {
    return Services.get("/customer/provinsi")
  }

  getKabupaten(params) {
    return Services.get("/customer/kabupaten", { params })
  }

  getKecamatan(params) {
    return Services.get("/customer/kecamatan", { params })
  }

  getDesa(params) {
    return Services.get("/customer/desa", { params })
  }
}

export default new PenjualanLangsungApi()
