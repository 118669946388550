import React, { PureComponent } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { DateConvert, RupiahConvert } from "utilities";
import Logo from "config";
import { TableSection } from "./Section";

const PrintContent = ({ dataInfo, dataTable, setDataTable }) => {
  const KopSuratSection = () => (
    <div className="d-flex mx-2 mb-4">
      <img src={Logo.LOGO} alt="" width={110} />
      <div style={{ marginLeft: 30 }}>
        <div className="font-weight-bold">CV. KRISNA ADVENTURE</div>
        <div>JL. RAYA SAMBANGAN, ALING - ALING WATERFALL, SINGARAJA - BALI</div>
        <div>Telp. : +62 877 6245 3130</div>
        <div>Email : krisnaadventures@gmail.com</div>
      </div>
    </div>
  );

  const InfoSection = ({ data, type }) => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td width={125}>{title}</td>
        <td className="pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    const InfoItemVertical = ({ title, value }) => (
      <div className="mb-2">
        <small style={{ fontSize: 12 }} className="text-secondary">
          {title}
        </small>
        <div style={{ fontSize: 14 }}>{value}</div>
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <Row>
              <Col>
                <InfoItemVertical
                  title="Tgl. Sales Order"
                  value={
                    data?.tgl_sales_order
                      ? DateConvert(new Date(data?.tgl_sales_order)).detail
                      : "-"
                  }
                />
                <InfoItemVertical title="No. Sales Order" value={data?.no_sales_order ?? "-"} />
                <InfoItemVertical
                  title="Batas Waktu Pengiriman"
                  value={data?.batas_waktu ? DateConvert(new Date(data?.batas_waktu)).detail : "-"}
                />
              </Col>
              <Col>
                <InfoItemVertical title="Sales" value={data?.nama_sales ?? "-"} />
                <InfoItemVertical title="Customer" value={data?.nama_customer ?? "-"} />
              </Col>
            </Row>
          </Col>
          <Col>
            <InfoItemVertical title="Alamat Pengiriman" value={data?.alamat_pengiriman ?? "-"} />
            <InfoItemVertical
              title="Catatan Sales Order"
              value={data?.catatan_so ?? data?.catatan ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Row>
              <Col>
                <InfoItemVertical
                  title="Tgl. Faktur Penjualan"
                  value={data?.tgl_faktur ? DateConvert(new Date(data?.tgl_faktur)).detail : "-"}
                />
              </Col>
              <Col>
                <InfoItemVertical title="No. Faktur Penjualan" value={data?.no_faktur ?? "-"} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const CaraPembayaranSection = () => {
    return (
      <div style={{ fontSize: 14 }}>
        <span>Cara Pembayaran :</span>
        <br />
        <ol>
          <li>Cash</li>
          <li>BCA (KRISNA ADVENTURE CV) : 8270808884</li>
          <li>BRI (CV KRISNA ADVENTURE) : 1729 01 000058 30 5</li>
        </ol>
      </div>
    );
  };

  return (
    <>
      <div className="p-4" style={{ backgroundColor: "white", height: "100%" }}>
        <KopSuratSection />
        <InfoSection data={dataInfo} />
        <TableSection dataInfo={dataInfo} dataTable={dataTable} setDataTable={setDataTable} />
        <CaraPembayaranSection />
      </div>
    </>
  );
};

export default class PrintFakturPenjualan extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PrintContent
        dataInfo={this.props.dataInfo}
        dataTable={this.props.dataTable}
        setDataTable={this.props.setDataTable}
      />
    );
  }
}
