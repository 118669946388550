import React, {
  forwardRef,
  useEffect
} from "react"
import {
  Col,
  Row
} from "react-bootstrap"
import {
  DateConvert
} from "utilities"
import { TableKeranjangBelanja } from "./components"

import Logo from "config"

const PrintPenjualanLangsung = forwardRef((props, ref) => {
  let id = props.id
  let data = props.data
  let dataInfo = props.dataInfo

  const InfoItemVertical = ({ title, value }) => (
    <div className='mb-2'>
      <small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
      <div className="text-capitalize" style={{ fontSize: 14 }}>{value}</div>
    </div>
  )

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <img src={Logo.LOGO} alt="" width={110} />
      <div style={{ marginLeft: 30 }}>
        <div className="font-weight-bold">CV. KRISNA ADVENTURE</div>
        <div>JL. RAYA SAMBANGAN, ALING - ALING WATERFALL, SINGARAJA - BALI</div>
        <div>Telp. : +62 877-6245-3130</div>
        <div>Email : krisnaadventures@gmail.com</div>
      </div>
    </div>
  )

  const TTDSection = () => {
    const TTDItems = ({ title, value }) => (
      <Col className="text-center">
        <div>{title}</div>
        <div style={{ marginTop: 100 }}>
          <hr style={{ maxWidth: 250, borderColor: "black" }} />
          {value}
        </div>
      </Col>
    )

    return (
      <Row className="mt-5 pt-3 d-flex justify-content-around">
        <TTDItems
          title="Penerima,"
          value=""
        />
        <TTDItems
          title="Hormat Kami,"
          value="CV. KRISNA ADVENTURE"
        />
      </Row>
    )
  }

  useEffect(() => { }, [id])

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />
      <div className="row pt-5">
        <div className="col">
          <InfoItemVertical title="Tanggal Penjualan Langsung" value={DateConvert(new Date(dataInfo.tgl_penjualan_langsung)).detail} />
          <InfoItemVertical title="No. Penjualan Langsung" value={dataInfo.no_penjualan_langsung} />
        </div>
        <div className="col">
          <InfoItemVertical title="Customer" value={dataInfo.nama_customer} />
          <InfoItemVertical title="Petugas Sales" value={dataInfo.nama_karyawan} />
        </div>
        <div className="col">
          <InfoItemVertical title="Metode Pembayaran" value={dataInfo.metode_pembayaran} />
        </div>
      </div>
      <div className="mt-4" />
      <TableKeranjangBelanja hideGudang type="DETAIL" data={data} dataInfo={dataInfo} />
      <TTDSection />
    </div >
  )
})

export default PrintPenjualanLangsung
