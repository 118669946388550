import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert, BackButton, DataStatus, ActionButton } from "components";
import { DateConvert } from "utilities";
import { FakturPenjualanApi } from "api";
import { TableSection } from "./Section";
import { 
  InfoSection,
  FormSection
} from '../Section'

const TambahFakturPenjualan = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default;
  const { id } = useParams();
  const history = useHistory();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [formRendered, setFormRendered] = useState(0);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? "-",
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      customer: data.nama_customer ?? "-",
      sales: data.nama_sales ?? "-",
      nama_proyek: data.nama_proyek ?? "-",
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        id_surat_jalan_detail: val.id_surat_jalan_detail ?? null,
        no_penerimaan_surat_jalan: val?.no_penerimaan_surat_jalan ?? null,
        tgl_penerimaan_surat_jalan: val?.tgl_penerimaan_surat_jalan ?? null,
        id_surat_jalan: val.id_surat_jalan ?? null,
        tgl_surat_jalan: val.tgl_surat_jalan ?? null,
        no_surat_jalan: val.no_surat_jalan ?? "-",
        no_delivery_order: val.no_delivery_order ?? "-",
        nama_item: val.nama_barang ?? "-",
        satuan: val.nama_satuan ?? "",
        qty: val.qty ? parseFloat(val.qty) : 0,
        harga: val.harga_satuan_jual ? parseInt(val.harga_satuan_jual) : 0,
        checked: false,
      };
    });

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    FakturPenjualanApi.getSingleSO({ id_sales_order: id })
      .then(({ data }) => {
        const getData = data.data;
        const mapDataInfo = getData ?? {}
        const mapDataTable = mappingDataTable(getData.surat_jalan ?? []);

        setDataInfo(mapDataInfo);
        setDataTable(mapDataTable);
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const formInitialValues = {
    id_sales_order: dataInfo.id_sales_order,
    tanggal: today,
    nomor: "",
    diskon: 0,
    ppn: 0,
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const filterDataTable = dataTable.filter((val) => val.checked === true);
    const getDataTable = filterDataTable.map((val) => {
      return {
        id_surat_jalan_detail: val.id_surat_jalan_detail,
      };
    });

    const finalValues = {
      id_sales_order: values.id_sales_order,
      tgl_faktur: values.tanggal,
      ppn: values.ppn,
      diskon: values.diskon,
      detail: getDataTable,
    };

    FakturPenjualanApi.save(finalValues)
      .then(() =>
        history.push("/sls/transaksi/faktur-penjualan", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!",
        });
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle("Faktur Penjualan");
  }, []);

  return (
    <Card style={{ minHeight: "80vh" }}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Data Faktur Penjualan</b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body className="pb-5">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {fetchingStatus.loading ? (
          <DataStatus loading text="Memuat . . ." />
        ) : fetchingStatus.success ? (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <InfoSection data={dataInfo} />
                <hr className="mb-1" />
                <FormSection
                  formik={formik}
                  formRendered={formRendered}
                  fetchingStatus={fetchingStatus}
                  setFormRendered={setFormRendered}
                />
                <hr className="mt-1" />
                <TableSection formik={formik} dataTable={dataTable} setDataTable={setDataTable} />
                <hr />
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    text="Simpan Faktur Penjualan"
                    loading={formik.isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahFakturPenjualan;
