import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col,
  Modal
} from 'react-bootstrap'
import Axios from 'axios'
import {
  CRUDLayout,
  THead,
  TBody,
  ThFixed,
  Tr,
  Th,
  Td,
  TdFixed,
  CreateButton,
  DataStatus,
  SelectSearch
} from 'components'
import {
  TransaksiSalesOrderApi
} from 'api';

const ModalTambahBarang = ({ modalBarangConfig, setModalBarangConfig, listBarang, setListBarang }) => {
  const [isLoading, setLoading] = useState(false);
  const [dataStokBarang, setDataStokBarang] = useState([])
  const [form, setForm] = useState({
    id_kelompok: '',
    id_jenis: '',
    id_barang: '',
  })
  const [dropdown, setDropdown] = useState({
    kelompok: [],
    jenis: [],
    barang: []
  })

  const handleChangeQtySO = (index, qty_item) => {
    const newData = [...dataStokBarang]

    newData[index].qty_item = qty_item
    newData[index].sub_total = parseInt(qty_item) * parseInt(newData[index].harga_terbaru ?? 0)
    setDataStokBarang(newData)
  }

  const getInitialData = () => {
    setLoading(true)

    Axios.all([
      TransaksiSalesOrderApi.getDropdownKelompok(),
      TransaksiSalesOrderApi.getDropdownJenis()
    ])
      .then(Axios.spread((resKelompok, resJenis) => {
        const kelompok = resKelompok.data.data.map(item => ({ value: item.id_kelompok, label: item.nama_kelompok, ...item }))
        const jenis = resJenis.data.data.map(item => ({ value: item.id_jenis, label: item.nama_jenis, ...item }))

        setDropdown({ kelompok, jenis })
      }))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const getDropdownBarang = async (id_jenis) => {
    await TransaksiSalesOrderApi.getDropdownBarang({ id_jenis })
      .then((res) => {
        const barang = res.data.data.map(item => ({ value: item.id_item_buaso, label: `${item.kode_item} | ${item.nama_item}`, ...item }))

        setDropdown((prev) => ({ ...prev, barang }))
      })
  }

  const getStokBarang = async (id_barang) => {
    await TransaksiSalesOrderApi.getStokBarang({ id_barang })
      .then((res) => setDataStokBarang(res?.data?.data))
  }

  useEffect(() => {
    getInitialData()

    return () => setDropdown({})
  }, [])

  return (
    <Modal
      size='lg'
      scrollable={true}
      show={modalBarangConfig}
      onHide={() => setModalBarangConfig(false)}
    >
      <Modal.Header closeButton>
        <h6 className="mb-0 text-primary">Tambah Barang</h6>
      </Modal.Header>

      {!isLoading ?
        <Modal.Body style={{ minHeight: 400 }}>
          <Row>
            <Col>
              <SelectSearch
                label='Kelompok Barang'
                placeholder='Pilih kelompok barang'
                defaultValue={form.id_kelompok}
                options={dropdown.kelompok}
                onChange={(e) => setForm(() => ({ id_kelompok: e.value, id_jenis: '', id_barang: '' }))}
              />
            </Col>
            <Col>
              <SelectSearch
                key={form.id_kelompok}
                isDisabled={!form.id_kelompok}
                label='Jenis Barang'
                placeholder='Pilih jenis barang'
                option={dropdown?.jenis.filter(item => item.id_kelompok === form.id_kelompok)}
                onChange={async (e) => {
                  await getDropdownBarang(e.value)
                  setForm((prev) => ({ ...prev, id_jenis: e.value, id_barang: '' }))
                }}
              />
            </Col>
          </Row>

          <SelectSearch
            key={form.id_jenis}
            isDisabled={!form.id_jenis}
            label='Item Barang'
            placeholder='Pilih item barang'
            option={dropdown?.barang ?? []}
            onChange={async (e) => {
              await getStokBarang(e.value)
              setForm((prev) => ({ ...prev, id_barang: e.value }))
            }}
          />

          {/* Table Section */}
          <small>Stok Gudang</small>
          <CRUDLayout.Table>
            <THead>
              <tr className='bg-light'>
                <ThFixed>No</ThFixed>
                <Th>Gudang</Th>
                <Th>Qty.Stok</Th>
                <Th width={30}>Qty.SO</Th>
              </tr>
            </THead>
            <TBody>
              {dataStokBarang?.map((val, index) => {
                return (
                  <Tr key={val.id_gudang}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td>{val.nama_gudang}</Td>
                    <Td textRight>{val.stok}</Td>
                    <Td>
                      <input
                        className="form-control p-0 text-right"
                        style={{ minWidth: "8rem", height: '1.8rem', fontSize: '0.8rem' }}
                        placeholder="0"
                        type="number"
                        name={`jml_${index}`}
                        max={val.stok}
                        min={0}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value > parseInt(val.stok)) { e.target.value = val.stok }
                        }}
                        onBlur={(e) => handleChangeQtySO(index, e.target.value)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </CRUDLayout.Table>
        </Modal.Body>
        : <DataStatus loading={isLoading} text='Memuat data...' />}

      {!isLoading && (
        <Modal.Footer>
          <CreateButton
            onClick={() => {
              // filter stok barang yang dipilih user dengan qty_item > 0
              const selectedStokBarang = dataStokBarang.filter((e) => e.hasOwnProperty("qty_item") && parseInt(e.qty_item) > 0);
              let copiedListBarang = [...listBarang]

              // fungsi hapus barang pada copiedListBarang, jika barang sudah ada di selectedStokBarang 
              const spliceBarang = (obj) => {
                copiedListBarang.forEach((item, index) => {
                  if (item.id_barang === obj.id_barang && item.id_gudang === obj.id_gudang) {
                    copiedListBarang.splice(index, 1)
                  }
                })
                setListBarang([...copiedListBarang]);
              };

              selectedStokBarang.forEach((obj) => spliceBarang(obj))
              setListBarang([...selectedStokBarang, ...copiedListBarang,])
              setModalBarangConfig(false)
            }} />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalTambahBarang