import { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {
  CreateModal,
  Table,
  ThFixed,
  Th,
  TdFixed,
  Td,
  SelectSearch,
  ActionButton,
} from 'components'
import { RupiahConvert } from 'utilities'
import { PenjualanLangsungApi } from 'api'

const ModalTambahBarang = ({ modalConfig, setModalConfig, data, setData }) => {
  const [dropdown, setDropdown] = useState({
    kelompok: [],
    jenis: [],
    barang: []
  })
  const [loading, setLoading] = useState({
    kelompok: false,
    jenis: false,
    barang: false,
    data_table: false
  })
  const [formBarang, setFormBarang] = useState({
    id_kelompok: "",
    id_jenis_barang: "",
    id_item_barang: ""
  })
  const [dataTable, setDataTable] = useState([])

  const handleSubmit = () => {
    const selected = dataTable.filter(val => val.qty_belanja > 0)
    const currentData = [...data]

    const checkExist = (item) => currentData.findIndex(val => (val.id_barang === item.id_barang) && (val.id_gudang === item.id_gudang))

    selected.forEach(item => {
      // get jumlah stok barang di gudang
      const qtyStok = dataTable.find(val => (val.id_barang === item.id_barang) && (val.id_gudang === item.id_gudang)).stok
      const getIndex = checkExist(item)

      if (getIndex > -1) {
        const floatPrevQtyBelanja = parseFloat(currentData[getIndex].qty_belanja)         // parse float qty_belanja pada item sebelumnya
        const floatCurrQtyBelanja = parseFloat(item.qty_belanja)                          // parse float qty_belanja pada item sekarang
        const accumulateQty = floatPrevQtyBelanja + floatCurrQtyBelanja                   // akumulasi qty_belanja

        currentData[getIndex].qty_belanja = (accumulateQty <= parseFloat(qtyStok)) ? accumulateQty : parseFloat(qtyStok)
      } else {
        currentData.unshift(item)
      }
    })

    setData(currentData)
    setModalConfig({ show: false })
  }

  const fetchKelompok = () => {
    setLoading(prev => ({ ...prev, kelompok: true }))
    PenjualanLangsungApi.getKelompokBarang()
      .then(({ data }) => {
        const mapData = data?.data?.map(val => ({ value: val.id_kelompok, label: val.nama_kelompok }))
        setDropdown(prev => ({ ...prev, kelompok: mapData ?? [] }))
      })
      .finally(() => setLoading(prev => ({ ...prev, kelompok: false })))
  }

  const fetchJenis = id_kelompok => {
    setLoading(prev => ({ ...prev, jenis: true }))
    PenjualanLangsungApi.getJenisBarang({ id_kelompok })
      .then(({ data }) => {
        const mapData = data?.data?.map(val => ({ value: val.id_jenis, label: val.nama_jenis }))
        setDropdown(prev => ({ ...prev, jenis: mapData ?? [] }))
      })
      .finally(() => setLoading(prev => ({ ...prev, jenis: false })))
  }

  const fetchBarang = id_jenis => {
    setLoading(prev => ({ ...prev, barang: true }))
    PenjualanLangsungApi.getItemBarang({ id_jenis })
      .then(({ data }) => {
        const mapData = data?.data?.map(val => ({ value: val.id_barang, label: val.nama_barang }))
        setDropdown(prev => ({ ...prev, barang: mapData ?? [] }))
      })
      .finally(() => setLoading(prev => ({ ...prev, barang: false })))
  }

  const fetchDataTable = id_barang => {
    setLoading(prev => ({ ...prev, data_table: true }))
    PenjualanLangsungApi.getDataBarang({ id_barang })
      .then(({ data }) => {
        setDataTable(data?.data ?? [])
      })
      .finally(() => setLoading(prev => ({ ...prev, data_table: false })))
  }

  useEffect(() => {
    fetchKelompok()

    return () => {
      setDataTable([])
      setDropdown({})
      setFormBarang({})
    }
  }, [])

  useEffect(() => {
    if (!formBarang.id_item_barang) setDataTable([])
  }, [formBarang.id_item_barang])

  return (
    <CreateModal
      title="Barang"
      size="lg"
      show={modalConfig.show && modalConfig.type === 'barang'}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Body>
        <Row>
          <Col>
            <SelectSearch
              label="Kelompok Barang*"
              placeholder="Pilih Kelompok Barang"
              option={dropdown.kelompok ?? []}
              loading={loading.kelompok}
              defaultValue={formBarang.id_kelompok ? dropdown.kelompok.find(val => val.value === formBarang.id_kelompok) : null}
              onChange={(opt) => {
                setFormBarang({ id_kelompok: opt.value, id_jenis_barang: "", id_item_barang: "" })
                fetchJenis(opt.value)
              }}
            />
          </Col>
          <Col>
            <SelectSearch
              key={formBarang.id_kelompok}
              isDisabled={!formBarang.id_kelompok}
              label="Jenis Barang*"
              placeholder="Pilih Jenis Barang"
              option={dropdown.jenis ?? []}
              loading={loading.jenis}
              defaultValue={formBarang.id_jenis_barang ? dropdown.jenis.find(val => val.value === formBarang.id_jenis_barang) : null}
              onChange={(opt) => {
                setFormBarang(prev => ({ ...prev, id_jenis_barang: opt.value, id_item_barang: "" }))
                fetchBarang(opt.value)
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <SelectSearch
              key={formBarang.id_jenis_barang}
              isDisabled={!formBarang.id_jenis_barang}
              label="Item Barang*"
              placeholder="Pilih Item Barang"
              option={dropdown.barang ?? []}
              loading={loading.barang}
              defaultValue={formBarang.id_item_barang ? dropdown.barang.find(val => val.value === formBarang.id_item_barang) : null}
              onChange={(opt) => {
                setFormBarang(prev => ({ ...prev, id_item_barang: opt.value }))
                fetchDataTable(opt.value)
              }}
            />
          </Col>
        </Row>
        <Table hover={false}>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <Th>Gudang</Th>
              <Th>Satuan Jual</Th>
              <Th>Qty. Stok</Th>
              <Th width={100}>Qty. Belanja</Th>
              <Th width={140}>Harga Satuan Jual</Th>
              <Th width={100}>Sub Total</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable?.map((val, index) => {
              const sub_total = Math.round(parseFloat(val?.qty_belanja || 0) * parseFloat(val.harga_terbaru || 0))

              return (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.nama_gudang}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td textRight>{parseInt(val.stok ?? 0)}</Td>
                  <Td >
                    <input
                      width={100}
                      type="number"
                      step={0.01}
                      min={0}
                      value={parseFloat(val.qty_belanja)}
                      className="text-right form-control"
                      onChange={(e) => {
                        const items = [...dataTable]
                        const item = { ...items[index] }

                        const value = Number.isInteger(e.target.value || 0) ? e.target.value : parseFloat(e.target.value || 0).toFixed(2)
                        const qty = parseFloat(value || 0) <= parseFloat(item.stok || 0) ? value : item.stok

                        item.qty_belanja = qty
                        items[index] = item

                        setDataTable(items)
                      }}
                    />
                  </Td>
                  <Td className="text-right">{RupiahConvert(String(parseFloat(val.harga_terbaru ?? 0))).detail}</Td>
                  <Td className="text-right">{RupiahConvert(String(sub_total || 0)).detail}</Td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          variant="outline-secondary"
          text="Batal"
          onClick={() => setModalConfig({ show: false, type: "" })}
        />
        <ActionButton
          text="Tambah"
          onClick={() => handleSubmit()}
        />
      </Modal.Footer>
    </CreateModal>
  )
}

export default ModalTambahBarang