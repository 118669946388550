import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Row, Col } from "react-bootstrap"
import { useFormik } from 'formik'
import * as Yup from "yup"
import {
  DatePicker,
  Input,
  SelectSearch,
  ActionButton,
  BackButton
} from "components"
import { DateConvert } from 'utilities'
import {
  TableKeranjangBelanja,
  ModalTambahBarang,
  ModalTambahCustomer
} from "./components"
import { PenjualanLangsungApi } from 'api'

const TambahPenjualanLangsung = ({ setNavbarTitle }) => {
  const TODAY = DateConvert(new Date()).default
  const history = useHistory()
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const [modalConfig, setModalConfig] = useState({ show: false, type: "customer" })
  const [data, setData] = useState([])
  const [dropdown, setDropdown] = useState({
    customer: [],
    karyawan: [],
    metode_pembayaran: [
      { value: "tunai", label: "Tunai" },
      { value: "kredit", label: "Kredit" },
    ]
  })
  const [loading, setLoading] = useState({
    customer: false,
    karyawan: false
  })

  const formInitialValues = {
    tanggal: "",
    nomor: "",
    id_customer: "",
    id_karyawan: "",
    metode_pembayaran: "",
    diskon: "0",
    ppn: "0",
    cash: "0",
    sisa: ""
  }
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Tanggal Penjualan Langsung diperlukan!"),
    id_customer: Yup.string().required("Customer diperlukan!"),
    id_karyawan: Yup.string().required("Karyawan diperlukan!"),
    metode_pembayaran: Yup.string().required("Metode Pembayaran diperlukan!"),
  })
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (data?.length > 0) {
      // Convert diskon & ppn into decimal
      const diskon = parseFloat(values.diskon) / 100
      const ppn = parseFloat(values.ppn) / 100

      const finalValue = {
        ...values,
        diskon,
        ppn,
        tgl_penjualan_langsung: values.tanggal,
        no_penjualan_langsung: values.nomor,
        nama_customer_nota: dropdown?.customer.find(item => item.value === values.id_customer).label,
        nama_karyawan_nota: dropdown?.karyawan.find(item => item.value === values.id_karyawan).label,
        detail: data?.map(item => ({
          id_item_buaso: item.id_barang,
          id_gudang: item.id_gudang,
          id_satuan: item.id_satuan,
          qty: item.qty_belanja,
          harga: item.harga_terbaru,
          nama_item_nota: item.nama_barang,
          nama_gudang_nota: item.nama_gudang,
          nama_satuan_nota: item.nama_satuan,
        }))
      }

      PenjualanLangsungApi.save(finalValue)
        .then(() => {
          history.push("/sls/transaksi/penjualan-langsung", {
            alert: { show: true, text: "Data berhasil ditambahkan!", variant: "primary" }
          })
        })
        .catch((err) => { console.log(err) })

    }
    else {
      setSubmitting(false)
      window.alert("Data Keranjang Belanja Kosong!")
    }
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler
  })

  const { values, errors, touched, isSubmitting, setFieldValue, setValues, handleChange, handleSubmit } = formik

  const getNomorHandler = date => {
    setIsNomorLoading(true)

    PenjualanLangsungApi.getNomor({ tanggal: date })
      .then(({ data }) => {
        const nomor = data.data

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: ""
        })
        window.alert("Nomor gagal dimuat")
      })
      .finally(() => {
        setIsNomorLoading(false)
      })
  }
  const fetchCustomer = () => {
    setLoading(prev => ({ ...prev, customer: true }))
    PenjualanLangsungApi.getCustomer()
      .then(({ data }) => {
        const mapData = data?.data?.map((i) => ({ value: i.id_customer, label: i.nama_customer }))
        setDropdown(prev => ({ ...prev, customer: mapData ?? [] }))
      })
      .finally(() => setLoading(prev => ({ ...prev, customer: false })))
  }
  const fetchKaryawan = () => {
    setLoading(prev => ({ ...prev, karyawan: true }))
    PenjualanLangsungApi.getKaryawan()
      .then(({ data }) => {
        const mapData = data?.data?.map((i) => ({ value: i.id_karyawan, label: i.nama_karyawan }))
        setDropdown(prev => ({ ...prev, karyawan: mapData ?? [] }))
      })
      .finally(() => setLoading(prev => ({ ...prev, karyawan: false })))
  }

  useEffect(() => {
    setNavbarTitle("Penjualan Langsung")
    getNomorHandler(TODAY)
    fetchCustomer()
    fetchKaryawan()

    return () => { }
  }, [])


  return (
    <>
      {/* Back Button */}
      <div className="d-flex justify-content-end align-items-center">
        <BackButton onClick={() => history.goBack()} />
      </div>

      <div className="mb-2 h6 font-weight-bold">Tambah Data Penjualan Langsung</div>

      {/* Form Card */}
      <Card>
        <Card.Body>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={3}>
              <DatePicker
                dateFormat='dd/MM/yyyy'
                label="Tgl. Penjualan Langsung"
                placeholderText="Pilih tanggal"
                selected={values.tanggal ? new Date(values.tanggal) : ""}
                onChange={date => {
                  const newDate = DateConvert(date).default
                  getNomorHandler(newDate)
                }}
                error={errors.tanggal && touched.tanggal}
                errorText={errors.tanggal && touched.tanggal ? errors.tanggal : ""}
              />
            </Col>

            <Col md={6}>
              <SelectSearch
                label="Customer*"
                placeholder="Pilih Customer"
                defaultValue={dropdown?.customer?.find(item => item.value === values.id_customer)}
                loading={loading.customer}
                option={dropdown.customer}
                onChange={(opt) => setFieldValue("id_customer", opt.value)}
                error={errors.id_customer && touched.id_customer}
                errorText={errors.id_customer && touched.id_customer ? errors.id_customer : ""}
              />
            </Col>

            <Col md={3}>
              <ActionButton
                className="btn btn-block mt-2"
                text="Register Customer Baru"
                onClick={() => setModalConfig({ show: true, type: "customer" })} />
            </Col>
          </Row>

          <Row className="d-flex justify-content-center align-items-center">
            <Col md={3}>
              <Input
                readOnly
                value={values.nomor}
                label="No. Penjualan Langsung"
                placeholder={isNomorLoading ? "Memuat nomor..." : "Pilih tanggal terlebih dahulu"}
              />
            </Col>

            <Col md={6}>
              <SelectSearch
                label="Petugas Sales*"
                placeholder="Pilih Petugas Sales"
                defaultValue={dropdown?.karyawan?.find(item => item.value === values.id_karyawan)}
                option={dropdown.karyawan}
                loading={loading.karyawan}
                onChange={(opt) => setFieldValue("id_karyawan", opt.value)}
                error={errors.id_karyawan && touched.id_karyawan}
                errorText={errors.id_karyawan && touched.id_karyawan ? errors.id_karyawan : ""}
              />
            </Col>

            <Col md={3}>
              <SelectSearch
                label="Metode Pembayaran*"
                placeholder="Pilih Metode Pembayaran"
                defaultValue={dropdown?.metode_pembayaran?.find(item => item.value === values.metode_pembayaran)}
                options={dropdown.metode_pembayaran}
                onChange={(opt) => setFieldValue("metode_pembayaran", opt.value)}
                error={errors.metode_pembayaran && touched.metode_pembayaran}
                errorText={errors.metode_pembayaran && touched.metode_pembayaran ? errors.metode_pembayaran : ""}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Button Tambah barang */}
      <div className="my-3 d-flex justify-content-end align-items-center">
        <ActionButton
          text="Tambah Barang"
          onClick={() => setModalConfig({ show: true, type: "barang" })}
        />
      </div>

      {/* Table Keranjang Belanja */}
      <TableKeranjangBelanja type="CREATE" formik={formik} data={data} setData={setData} />

      <div className="my-3 d-flex justify-content-end align-items-center">
        <ActionButton
          text="Simpan"
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        />
      </div>

      {/* Modal Tambah Barang */}
      {modalConfig.show && modalConfig.type === "barang" && (
        <ModalTambahBarang
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={data}
          setData={setData}
        />
      )}

      {/* Modal Tambah Customer*/}
      {modalConfig.show && modalConfig.type === "customer" && <ModalTambahCustomer fetchCustomer={fetchCustomer} modalConfig={modalConfig} setModalConfig={setModalConfig} />}
    </>
  )
}

export default TambahPenjualanLangsung