import React, { useState, useEffect } from 'react'
import { Spinner, DropdownButton, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { CRUDLayout, Alert, DataStatus, Table, Th, Td, FilterButton, ThFixed, TdFixed } from 'components'
import { DateConvert } from 'utilities'
import RupiahConvert from "utilities/RupiahConvert/RupiahConvert"
import { LaporanPenjualanApi } from 'api'
import { ModalFilter } from './Section'
import { saveAs } from 'file-saver'

const LaporanPenjualan = ({ setNavbarTitle }) => {
    const [data, setData] = useState([])
    const [isFetchingReport, setIsFetchingReport] = useState(false) 
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    const dropdownUrutan = [
        { value: "terbaru", label: "Terbaru" },
        { value: "terlama", label: "Terlama" },
        { value: "name", label: "Berdasarkan Nama Customer" },
        { value: "qty_order_terbesar", label: "Transaksi Terbesar" },
        { value: "qty_order_terkecil", label: "Transaksi Terkecil" },
    ]

    const [dataFilter, setDataFilter] = useState({ active: false, tanggal_awal: undefined, tanggal_akhir: undefined, order_option: undefined })
    const [alertConfig, setAlertConfig] = useState({ show: false, variant: 'primary', text: '' })

    const getReportData = () => {
        setIsFetchingReport(true)

        const params = {};
        if (dataFilter?.tanggal_awal) params.tanggal_awal = DateConvert(new Date(dataFilter?.tanggal_awal)).default
        if (dataFilter?.tanggal_akhir) params.tanggal_akhir = DateConvert(new Date(dataFilter?.tanggal_akhir)).default
        if (dataFilter?.order_option) if(dataFilter?.order_option !== "name") params.order_option = dataFilter?.order_option


        LaporanPenjualanApi.getReport(params)
        .then(({ data }) => setData(data?.data ?? []))
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" }))
        .finally(() => setIsFetchingReport(false))
    }
    const exportData = tipe => {
        setLoadingExport(true)

        const params = {};
        if (dataFilter?.tanggal_awal) params.tanggal_awal = DateConvert(new Date(dataFilter?.tanggal_awal)).default
        if (dataFilter?.tanggal_akhir) params.tanggal_akhir = DateConvert(new Date(dataFilter?.tanggal_akhir)).default
        if (dataFilter?.order_option) if(dataFilter?.order_option !== "name") params.order_option = dataFilter?.order_option
        if (tipe) params.tipe = tipe

        LaporanPenjualanApi.export(params)
        .then(res => {
            const link = res?.data?.data
            const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
            saveAs(parse, "LaporanPenjualan")
        })
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Export Data gagal" }))
        .finally(() => setLoadingExport(false))
    }

    useEffect(() => {
        setNavbarTitle("Report Penjualan")
        return () => { setData([]) }
    }, [setNavbarTitle])

    useEffect(() => {
        getReportData()
    }, [dataFilter])

    const grandTotal = () => {
        const totalKeseluruhanHarga = data?.reduce((prevValue, currentValue) => {
          const checkTotal = currentValue.total_harga ? parseFloat(currentValue.total_harga) : 0
          return prevValue + checkTotal
        }, 0)
  
        return {
          prices: totalKeseluruhanHarga,
        }
    }

    const PageContent = () => {
        const DataTable = () => (
            <>
                <span style={{fontSize:"14px"}}><b>List Penjualan</b></span>
                <Table>
                    <thead>
                        <tr>
                            <ThFixed>No</ThFixed>
                            <Th>Informasi Faktur Penjualan</Th>
                            <Th>No Surat Jalan</Th>
                            <Th>Item Barang</Th>
                            <Th>No Sales Order</Th>
                            <Th>Customer</Th>
                            <Th>Sub Total Harga</Th>
                            <Th>Diskon</Th>
                            <Th>PPN</Th>
                            <Th>Total</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {!data || data.length > 0 ?
                            data.map((val, index) => {
                                const rowSpan = val.detail.length ?? 1

                                return val.detail.map((item, index_item) => {
                                    return (
                                        <tr key={index}>
                                            {index_item === 0 && 
                                                <>
                                                    <TdFixed rowSpan={rowSpan}>{index + 1}</TdFixed>
                                                    <Td rowSpan={rowSpan}>
                                                        <div>{val?.no_faktur ?? "-"}</div>
                                                        <div>{val.tgl_faktur ? DateConvert(new Date(val.tgl_faktur)).defaultDMY : "-"}</div>
                                                    </Td>
                                                </>
                                            }
                                            <Td>{item.no_surat_jalan ?? "-"}</Td>
                                            <Td>{item.nama_item ?? "-"}</Td>
                                            <Td>{item.no_sales_order ?? "-"}</Td>
                                            {index_item === 0 && 
                                                <>
                                                    <Td rowSpan={rowSpan}>{val.nama_customer ?? "-"}</Td>
                                                    <Td rowSpan={rowSpan}>{val.subtotal ? RupiahConvert(String(val.subtotal)).getWithComa : 'Rp. 0'}</Td>
                                                    <Td rowSpan={rowSpan}>{val.total_diskon ? RupiahConvert(String(val.total_diskon)).getWithComa : 'Rp. 0'}</Td>
                                                    <Td rowSpan={rowSpan}>{val.total_ppn ? RupiahConvert(String(val.total_ppn)).getWithComa : 'Rp. 0'}</Td>
                                                    <Td rowSpan={rowSpan}>{val.total_harga ? RupiahConvert(String(val.total_harga)).getWithComa : 'Rp. 0'}</Td>
                                                </>
                                            }
                                        </tr>
                                    )
                                })
                            })
                            : <tr><td colSpan={10} className="text-center">Tidak ada data</td></tr>
                        }
                        <tr>
                            <Td colSpan={9}><b className='d-flex justify-content-end'>Total Pembelian</b></Td>
                            <Td className="text-right">{grandTotal().prices ? RupiahConvert(String(parseFloat(grandTotal().prices))).getWithComa : "Rp. 0"}</Td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
        return <DataTable />
    }

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <div className="d-flex mb-2 ml-2">
                    <FilterButton active={dataFilter?.active} onClick={() => setShowModalFilter(true)} />
                    <DropdownButton
                        className='ml-3'
                        variant='warning text-white'
                        disabled={loadingExport ? true : false}
                        title={loadingExport
                            ? <>
                                <Spinner animation="border" size="sm" />
                                <span className="ml-2 mr-2  ">Loading...</span>
                              </>
                            : <span className="text-white mr-2">EXPORT DATA</span>
                        }
                    >
                        <Dropdown.Item onClick={() => exportData("excel")}>Excel (.xlsx)</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportData("pdf")}>PDF (.pdf)</Dropdown.Item>
                    </DropdownButton>
                </div>
            </CRUDLayout.Head>
            
            <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => setAlertConfig({ ...alertConfig, show: false })}
            />
            {dataFilter?.active === false &&
                <Alert show={true} variant="danger" text="Filter terlebih dahulu untuk melihat data!" />
            }
            
            {isFetchingReport
                ? <DataStatus loading={true} text="Memuat data . . ." />
                : <div style={{ minHeight: '45vh' }}><PageContent /></div>
            }
            
            { showModalFilter && <ModalFilter
                    show={showModalFilter}
                    setShow={setShowModalFilter}
                    dropdownUrutan={dropdownUrutan}
                    data={dataFilter}
                    setData={setDataFilter}
                />
            }
        </CRUDLayout>
    )
}

export default LaporanPenjualan